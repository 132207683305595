import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useUpdateReconciliationTemplate() {
  const { error } = useNotification();

  const { mutate, onDone, onError } = useMutation(RECONCILIATION_TEMPLATES_UPDATE_MUTATION);

  onError((err) => {
    console.error('useUpdateReconciliationTemplate', err);
    error();
  });

  return {
    mutate,
    onDone,
  };
}

const RECONCILIATION_TEMPLATES_UPDATE_MUTATION = gql`
  mutation reconciliationTemplateUpdate($params: ReconciliationTemplateInput!, $businessId: ID!, $supplierId: ID!) {
    reconciliationTemplateUpdate(params: $params, businessId: $businessId, supplierId: $supplierId) {
      supplierId
      autoCreateOrderFromInvoice
      autoCreateOrderFromCreditNote
      balanceAlignmentRequired
      consolidatedInvoice
      createBillingFrom
    }
  }
`;
