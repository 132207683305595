import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';
import { useNotification, useLoading } from '@/modules/core';

export function useReconciliationTemplates(variables) {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(RECONCILIATION_TEMPLATES_QUERY, variables, () => ({
    enabled: !!variables.value.businessId,
  }));
  const reconciliationTemplates = useResult(result, {});
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('useReconciliationTemplates', err);
    error();
  });

  return {
    reconciliationTemplates,
    loading,
    refetch,
  };
}

const RECONCILIATION_TEMPLATES_QUERY = gql`
  query reconciliationTemplates($businessId: ID!) {
    reconciliationTemplates(businessId: $businessId) {
      data {
        supplierId
        autoCreateOrderFromInvoice
        autoCreateOrderFromCreditNote
        consolidatedInvoice
        balanceAlignmentRequired
        note
        createBillingFrom
      }
      default {
        autoCreateOrderFromInvoice
        autoCreateOrderFromCreditNote
        consolidatedInvoice
        balanceAlignmentRequired
        createBillingFrom
      }
    }
  }
`;
