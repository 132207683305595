import { computed } from 'vue';
import { isNil } from 'ramda';
import { gql } from '@apollo/client/core';
import { useQuery, useMutation } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useMissingEvents(variables, options) {
  const { error } = useNotification();

  const { result, loading: queryLoading, onError, refetch } = useQuery(MISSING_EVENTS_QUERY, variables, options);
  const missingEventsResult = computed(() => result?.value?.missingEvents?.nodes ?? []);
  const loading = useLoading(queryLoading, variables);

  const missingEvents = computed(() =>
    missingEventsResult.value.map((missingEvent) => {
      if (missingEvent.context) {
        const calculatedAmount =
          missingEvent.context.totalAmount ??
          missingEvent.context.debitAmount ??
          (!isNil(missingEvent.context.creditAmount) ? missingEvent.context.creditAmount * -1 : null);
        return {
          ...missingEvent,
          context: {
            ...missingEvent.context,
            calculatedAmount,
          },
        };
      }
      return missingEvent;
    })
  );

  onError((err) => {
    console.error('useMissingEvents', err);
    error();
  });

  return {
    missingEvents,
    refetch,
    loading,
  };
}

const MISSING_EVENTS_QUERY = gql`
  query missingEvents($businessId: ID!, $supplierId: ID, $estimatedDateFrom: ISODate, $estimatedDateTo: ISODate) {
    missingEvents(
      businessId: $businessId
      supplierId: $supplierId
      estimatedDateFrom: $estimatedDateFrom
      estimatedDateTo: $estimatedDateTo
      resolved: false
    ) {
      nodes {
        id
        supplierId
        context
        parentDocumentId
        reference
        estimatedDate
        possibleTypes
        resolved
      }
    }
  }
`;

export function usePatchMissingEvent() {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(PATCH_MISSING_EVENT_MUTATION);

  onError((param) => {
    console.error('usePatchMissingEvent', param);
    error();
  });

  return {
    patchMissingEvent: mutate,
    loading,
    onDone,
    onError,
  };
}

const PATCH_MISSING_EVENT_MUTATION = gql`
  mutation patchMissingEvent($id: ID!, $data: PatchMissingEventInput!) {
    patchMissingEvent(id: $id, data: $data) {
      resolved
      modifiedBy
    }
  }
`;

export function usePatchMultipleMissingEvents() {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(PATCH_MULTIPLE_MISSING_EVENTS_MUTATION);

  onError((param) => {
    console.error('usePatchMultipleMissingEvents', param);
    error();
  });

  return {
    patchMultipleMissingEvents: mutate,
    loading,
    onDone,
    onError,
  };
}

const PATCH_MULTIPLE_MISSING_EVENTS_MUTATION = gql`
  mutation patchMissingEvents($batchParams: [PatchMissingEventInputBatch!]!) {
    patchMissingEvents(batchParams: $batchParams) {
      id
      resolved
      modifiedBy
    }
  }
`;
