import { gql } from '@apollo/client/core';
import { useQuery, useResult } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useReconciliationTemplate(variables) {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(RECONCILIATION_TEMPLATE_QUERY, variables, () => ({
    enabled: !!variables.value.businessId && !!variables.value.supplierId,
  }));
  const reconciliationTemplate = useResult(result, {});
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('useReconciliationTemplate', err);
    error();
  });

  return {
    reconciliationTemplate,
    loading,
    refetch,
  };
}

const RECONCILIATION_TEMPLATE_QUERY = gql`
  query reconciliationTemplate($businessId: ID!, $supplierId: ID!) {
    reconciliationTemplate(businessId: $businessId, supplierId: $supplierId) {
      supplierId
      autoCreateOrderFromInvoice
      autoCreateOrderFromCreditNote
      consolidatedInvoice
      balanceAlignmentRequired
      note
      createBillingFrom
    }
  }
`;
