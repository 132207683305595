import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useUpdateReconciliation() {
  const { error } = useNotification();

  const { mutate, loading, onDone, onError } = useMutation(RECONCILIATION_UPDATE_MUTATION);

  onError((err) => {
    console.error('useUpdateReconciliation', err);
    error();
  });

  return {
    mutate,
    loading,
    onDone,
  };
}

const RECONCILIATION_UPDATE_MUTATION = gql`
  mutation update($id: ID!, $patchParams: ReconciliationPatchInput) {
    reconciliationUpdate(id: $id, patchParams: $patchParams) {
      id
      actionsPerformed
      statusReflection {
        reason
        updatedBy {
          firstName
          lastName
        }
        updatedAt
      }
      balanceAlignment {
        required
        validated
      }
    }
  }
`;
