import { computed, ref } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

const sleep = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function useReconciliation(reconciliationId) {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(
    RECONCILIATION_QUERY,
    () => ({ id: reconciliationId.value }),
    () => ({ enabled: !!reconciliationId.value })
  );

  const reconciliation = computed(() => result.value?.reconciliation ?? null);

  const variables = ref({ reconciliationId });
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('reconciliationModal - useReconciliation', err);
    error();
  });

  const refetchWhileStale = async () => {
    let refetchResult = await refetch();
    while (refetchResult?.data.reconciliation.stale) {
      await sleep(1000);
      refetchResult = await refetch();
    }
  };

  return {
    reconciliation,
    refetch: refetchWhileStale,
    loading,
  };
}

const RECONCILIATION_QUERY = gql`
  query getReconciliation($id: ID!) {
    reconciliation(id: $id) {
      id
      paymentDueDate
      supplierId
      businessId
      customer {
        id
        name
      }
      periodStart
      periodEnd
      balanceAlignment {
        required
        validated
        note
      }
      closed
      actionsPerformed
      stale
      status {
        status
        details {
          key
          status
          details {
            key
            status
          }
        }
      }
      statusOverride {
        status
        comment
        modifiedAt
        modifiedByDetails {
          firstName
          lastName
        }
      }
      statusReflection {
        reason
        updatedBy {
          firstName
          lastName
        }
        updatedAt
      }
      payments {
        id
        completed
        billingLinks {
          billingId
          amount
        }
      }
      billings {
        id
        date: dateNew
        supplierId
        netAmount
        taxAmount
        totalAmount
        allocationRequired
        eventReferences {
          allocationNumber
        }
        imbalances {
          billedAmounts {
            id
            amount
          }
          orderedAmounts {
            id
            amount
          }
        }
        orderLinks {
          order {
            id
          }
        }
        deliveryRefs {
          delivery {
            id
          }
        }
        related {
          id
          date: dateNew
          netAmount
          taxAmount
          totalAmount
          orderLinks {
            order {
              id
            }
            amount
          }
          source {
            document: documentNew2 {
              id
              documentNumber
              type
            }
          }
        }
        source {
          document: documentNew2 {
            id
            documentNumber
            type
          }
        }
      }
      uncertainBillings {
        id
        date
        supplierId
        netAmount
        taxAmount
        totalAmount
        orderLinks {
          externalRef
        }
        deliveryRefs {
          externalRef
        }
        source {
          document: documentNew2 {
            id
            documentNumber
            type
          }
        }
      }
      deliveries {
        id
        orderIds
        supplierId
        products {
          id
          productId
          quantity
        }
      }
      orders {
        id
        netAmount
        date
        source {
          ref
          document: documentNew2 {
            id
            type
            documentNumber
          }
        }
        products {
          id
          productId
          quantity
          price
          orderId
          orderDate
        }
        itemsCount {
          ordered
        }
        differences {
          product {
            id
            name
            sku
          }
          productId
          amount
          pricingAmount
          quantityAmount
          price {
            customerValue
            supplierValue
            solved
          }
          quantity {
            customerValue
            supplierValue
            solved
          }
          discount {
            customerValue
            supplierValue
            solved
          }
          netPrice {
            customerValue
            supplierValue
            solved
          }
        }
      }
      unbilledOrders {
        id
        orderId
        businessId
        supplierId
        customerId
        date
        netAmount
        type
      }
    }
  }
`;
