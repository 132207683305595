import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

export function useStatusOverrideRemove() {
  const { mutate, loading, onDone, onError } = useMutation(REMOVE_STATUS_OVERRIDE_MUTATION);
  return { mutate, loading, onDone, onError };
}

export const REMOVE_STATUS_OVERRIDE_MUTATION = gql`
  mutation reconciliationStatusOverrideRemove($reconciliationId: ID!) {
    reconciliationStatusOverrideRemove(reconciliationId: $reconciliationId)
  }
`;

export function useStatusOverrideUpdate() {
  const { mutate, loading, onDone, onError } = useMutation(STATUS_OVERRIDE_UPDATE);
  return { mutate, loading, onDone, onError };
}

const STATUS_OVERRIDE_UPDATE = gql`
  mutation reconciliationStatusOverrideUpdate($reconciliationId: ID!, $params: IntegratedStatusOverrideInput!) {
    reconciliationStatusOverrideUpdate(reconciliationId: $reconciliationId, params: $params) {
      status
      comment
      modifiedAt
      modifiedByDetails {
        firstName
        lastName
      }
    }
  }
`;
