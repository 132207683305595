import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useCloseReconciliation() {
  const { error } = useNotification();

  const { mutate, onDone, onError } = useMutation(CLOSE_RECONCILIATION_MUTATION);

  onError((err) => {
    console.error('reconciliation useCloseReconciliation', err);
    error();
  });

  return {
    mutate,
    onDone,
  };
}

const CLOSE_RECONCILIATION_MUTATION = gql`
  mutation closeReconciliation($id: ID!) {
    closeReconciliation(id: $id) {
      id
      closed
    }
  }
`;
