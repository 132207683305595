import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';

export function useCreateReconciliationTemplate() {
  const { error } = useNotification();

  const { mutate, onDone, onError } = useMutation(RECONCILIATION_TEMPLATES_CREATE_MUTATION);

  onError((err) => {
    console.error('useCreateReconciliationTemplate', err);
    error();
  });

  return {
    mutate,
    onDone,
  };
}

const RECONCILIATION_TEMPLATES_CREATE_MUTATION = gql`
  mutation reconciliationTemplateCreate($params: ReconciliationTemplateInput!, $businessId: ID!, $supplierId: ID!) {
    reconciliationTemplateCreate(params: $params, businessId: $businessId, supplierId: $supplierId)
  }
`;
