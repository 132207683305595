import { computed, reactive } from 'vue';
import { useTenancy } from '@/modules/auth';
import { useSendbirdChannels } from '@/modules/chatModal';

const channelState = reactive({
  channelUrls: null,
  unreadMessagesCount: {},
});

const useChannel = (participants) => {
  const { currentTenant } = useTenancy();

  if (participants?.value && currentTenant?.value?.id) {
    channelState.channelUrls = computed(() => participants.value.map((participant) => `reconciliation_${participant}`));
  }

  const { channels, refetch: refetchChannels } = useSendbirdChannels(computed(() => channelState.channelUrls));

  if (participants) {
    channelState.unreadMessagesCount = computed(() => {
      return channels.value
        .map((channel) => {
          const [, channelReconciliationId] = channel.channel_url.split('_');
          const isMember = channel.member_state && channel.member_state !== 'none';
          const hasMessages = !!channel.last_message;

          return {
            channelReconciliationId,
            unreadMessageCount: isMember ? channel.unread_message_count : Number(hasMessages),
            hasMessages,
          };
        })
        .filter(
          ({ channelReconciliationId, hasMessages }) =>
            participants.value.includes(channelReconciliationId) && hasMessages
        )
        .reduce(
          (acc, { channelReconciliationId, unreadMessageCount }) => ({
            ...acc,
            [channelReconciliationId]: unreadMessageCount,
          }),
          {}
        );
    });
  }

  return {
    onChannelNewMessage: refetchChannels,
    onChannelReadMessage: refetchChannels,
    channelUnreadMessagesCount: computed(() => channelState.unreadMessagesCount),
  };
};

export default useChannel;
